var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-8 col-md-8 col-sm-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("name") ? "border-danger" : "",
                  attrs: {
                    placeholder: "Tên",
                    "data-vv-name": "name",
                    "data-vv-as": "Tên",
                  },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
                _vm.errors.has("name")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("name"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("my-editor", {
                  attrs: {
                    apiKey: "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                  },
                  model: {
                    value: _vm.form.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12 sidebar-right" }, [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body form-card" },
          [
            _c("label", { staticStyle: { margin: "0" } }, [_vm._v("Hiển thị")]),
            _c("el-switch", {
              staticClass: "pull-right",
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.form.status,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "status", $$v)
                },
                expression: "form.status",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Cập nhật")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Tên")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Mô tả")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }